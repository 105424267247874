import { FC } from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";

import CardImages from "@components/checkout/card-images";
import { useStaticData } from "@context/static";
import { SocialIcons } from "@constants/social-icons";

const Footer: FC = () => {
  const { socialLinks, contentPages } = useStaticData();
  const { t } = useTranslation("common", { keyPrefix: "footer" });
  const { locale } = useRouter();

  // TODO: could also load icons from db -- feel like overall static data
  //  config/fetching could be handled in a more performant/error prone way --
  //  will revisit later
  const socials = socialLinks
    .filter((sl) => sl.name.toLowerCase() in SocialIcons)
    .map((sl) => ({
      ...sl,
      icon: SocialIcons[sl.name as keyof typeof SocialIcons],
    }));

  return (
    <footer className="bg-black z-20">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center pt-4 border-t"
          aria-label="Footer"
        >
          <div className="px-5 py-2">
            <Link href="/partners">
              <a className="p-1 text-base text-gray-300 border-b-2 border-transparent hover:border-white hover:text-white">
                {t("footer.partners")}
              </a>
            </Link>
          </div>
          {contentPages.map(({ slug, name, nameEn }) => (
            <div key={slug} className="px-5 py-2">
              <Link href={{ pathname: "/[slug]", query: { slug } }}>
                <a className="p-1 text-base text-gray-300 border-b-2 border-transparent hover:border-white hover:text-white">
                  {locale === "en" ? nameEn : name}
                </a>
              </Link>
            </div>
          ))}
        </nav>
        <div className="my-4">
          <CardImages {...{ size: "small", dark: true }} />
        </div>
        <div className="mt-8 flex justify-center space-x-6">
          {socials.map((item) => (
            <a
              key={item.name}
              href={item.link}
              className="text-gray-300 hover:text-white"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-300">
          &copy; {t("footer.copyright")}{" "}
          <a
            className="border-b-2 hover:text-white font-bold"
            href="https://digitaleagle.me"
          >
            Digital Eagle
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
