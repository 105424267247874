import { Product } from "@prisma/client";
import Link from "next/link";
import { FC } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import { getMediaSizes, imageLoader } from "@utils/get-media";
import { formatPrice } from "@utils/price";

type Props = Pick<
  Product,
  | "cardName"
  | "cardNameEn"
  | "descriptionEn"
  | "description"
  | "featuredPhoto"
  | "price"
  | "slug"
>;

const SearchPredictionCard: FC<Props> = ({
  cardName,
  cardNameEn,
  description,
  descriptionEn,
  featuredPhoto,
  price,
  slug,
}) => {
  const { locale } = useRouter();
  const isEn = locale === "en";

  return (
    <Link
      href={{
        pathname: "/products/[slug]",
        query: { slug },
      }}
    >
      <a className="flex items-stretch justify-between py-2 space-x-2 h-28">
        <div className="w-3/12 overflow-hidden">
          <div className="aspect-w-8 aspect-h-10">
            <Image
              src={featuredPhoto}
              alt={isEn ? cardNameEn : cardName}
              layout="fill"
              objectFit="cover"
              loader={imageLoader}
              sizes={getMediaSizes([{ size: "20vw" }])}
            />
          </div>
        </div>
        <div className="w-9/12 flex flex-col justify-evenly">
          <div className="flex items-center justify-between w-full">
            <h3
              dangerouslySetInnerHTML={{ __html: isEn ? cardNameEn : cardName }}
            />
            <span>{formatPrice(price)}</span>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: isEn ? descriptionEn : description,
            }}
            className="w-full mt-2 line-clamp-2"
          />
        </div>
      </a>
    </Link>
  );
};

export default SearchPredictionCard;
