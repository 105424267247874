import MinusIcon from "@heroicons/react/outline/MinusIcon";
import PlusIcon from "@heroicons/react/outline/PlusIcon";
import { FC, useState } from "react";

interface Props {
  quantity: number;
  increase(): void;
  decrease(): void;
  set(amount: number): void;
}

const QuantityInput: FC<Props> = ({ quantity, increase, decrease, set }) => {
  const [minusAnimate, setMinusAnimate] = useState(false);
  const [plusAnimate, setPlusAnimate] = useState(false);

  return (
    <div className="flex flex-row justify-center items-stretch w-1/5 bg-white rounded border-gray-300 border-4 divide-x-4 min-w-24 text-black">
      <div className="w-1/4 flex items-center justify-center">
        <button
          onClick={() => {
            if (quantity > 1) {
              decrease();
              setMinusAnimate(true);
            }
          }}
          className="w-full focus:outline-none"
        >
          <MinusIcon
            className={`transition-all duration-200 ${
              minusAnimate && "animate-scale"
            }`}
            onAnimationEnd={() => setMinusAnimate(false)}
          />
        </button>
      </div>
      <div className="custom-number w-1/2">
        <input
          type="number"
          onChange={({ target: { value } }) => set(+value)}
          value={quantity}
          min={1}
          max={100}
          className="block w-full h-full border-none outline-none focus:outline-none focus:ring-0 focus:ring-inset focus:ring-gray-400 text-center font-semibold"
        />
      </div>
      <div className="w-1/4 flex items-center justify-center">
        <button
          onClick={() => {
            if (quantity < 100) {
              increase();
              setPlusAnimate(true);
            }
          }}
          className="w-full focus:outline-none"
        >
          <PlusIcon
            className={`transition-all duration-200 ${
              plusAnimate && "animate-scale"
            }`}
            onAnimationEnd={() => setPlusAnimate(false)}
          />
        </button>
      </div>
    </div>
  );
};

export default QuantityInput;
