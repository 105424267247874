import { ParsedUrlQuery } from "querystring";

import { AsyncReturnType, IterableElement, ValueOf } from "type-fest";
import algoliasearch from "algoliasearch";
import { Product } from "@prisma/client";

import { parseQuery } from "@utils/query-parsing";
import { Props } from "@interfaces/categories";
import {
  algoliaAppId,
  algoliaSearchKey,
  algoliaIndexName,
} from "@config/algolia";

export const HITS_PER_PAGE = 48 as const;
export const HITS_FOR_PREDICTION = 6 as const;

const algolia = algoliasearch(algoliaAppId, algoliaSearchKey);

const index = algolia.initIndex(algoliaIndexName);

const searchPublished = async (...args: Parameters<typeof index.search>) => {
  const [query, options] = args;
  // eslint-disable-next-line prefer-const
  let { filters = "", ...rest } = options ?? {};
  if (filters) {
    filters += " AND ";
  }
  filters += "published:true";
  return index.search<Product>(query, { filters, ...rest });
};

export type Hit = IterableElement<
  ValueOf<AsyncReturnType<typeof searchPublished>, "hits">
>;

export interface SearchResult extends Props {
  search: string;
  products: Hit[];
}

export async function getProducts(
  query: ParsedUrlQuery
): Promise<SearchResult> {
  const filters = parseQuery(query);
  const page = "page" in query ? +query["page"] : 1;
  const searchQuery = "query" in query ? (query["query"] as string) : "";

  const {
    hits: products = [],
    facets = {},
    nbHits: totalNumberOfProducts = 0,
  } = await searchPublished(searchQuery, {
    hitsPerPage: HITS_PER_PAGE,
    page: page - 1,
  });

  return {
    facets,
    products,
    totalNumberOfProducts,
    filters,
    search: searchQuery,
  };
}

export async function getPredictions(query: string): Promise<Hit[]> {
  const { hits: products = [] } = await searchPublished(query, {
    hitsPerPage: HITS_FOR_PREDICTION,
  });

  return products;
}

export { index };
