import { FC } from "react";
import Head from "next/head";

import Nav from "@components/common/nav";
import Newsletter from "@components/common/newsletter";
import Footer from "@components/common/footer";

type Props = {
  title: string;
  description: string;
  className?: string;
};

const logoUrl = "https://assets.renyahome.me/logo-white_400.webp";

const Layout: FC<Props> = ({ children, title, className, description }) => {
  return (
    <div className={`h-screen flex flex-col ${className || ""}`}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={logoUrl} />
        <meta itemProp="image" content={logoUrl} />
        <meta name="twitter:image:src" content={logoUrl} />
        <meta name="og:image" content={logoUrl} />
      </Head>
      <div className="flex-shrink-0">
        <Nav />
      </div>
      <div className="flex-1 bg-main-bg">{children}</div>
      <div className="flex-shrink-0 flex flex-col bg-black">
        <Newsletter />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
