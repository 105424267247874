import { FC, FormEvent, useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { useStaticData } from "@context/static";
import { classNames } from "@utils/class-names";

const Newsletter: FC = () => {
  const [active, setActive] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const { locale } = useRouter();
  const { newsletter } = useStaticData();
  const { t } = useTranslation("common", { keyPrefix: "footer" });

  const isValid = true;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/newsletter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          Object.fromEntries(new FormData(e.target as HTMLFormElement))
        ),
      });

      if (response.ok) {
        setSignedUp(true);
      }
    } catch (error) {
      // the exception is caught in order not to be caught by sentry as error
      // should only happen if fetch fails which should be caught on the backend
      // so there is no need to include the error twice
    }
  };

  return (
    <div className="flex items-start justify-center flex-wrap pt-12 px-8 md:px-0">
      <div className="text-sm text-center md:text-left mb-4 md:mr-2">
        <div className="max-w-xs text-gray-300 w-full">
          <h3 className="text-lg text-white font-bold">
            {t("newsletter.heading")}
          </h3>
          <p>{locale === "en" ? newsletter.textEn : newsletter.text}</p>
        </div>
      </div>
      <form
        className={classNames(
          "flex justify-center md:mt-0 md:items-between items-start flex-wrap w-full md:w-auto",
          signedUp ? "mt-4" : ""
        )}
        onSubmit={handleSubmit}
      >
        <div className="w-full md:w-auto relative">
          {signedUp && (
            <div className="absolute -translate-y-7 text-green-500">
              <div>{t("newsletter.success")}</div>
            </div>
          )}
          <label htmlFor="newsletter-email">
            <input
              type="email"
              name="email"
              id="newsletter-email"
              className="w-full md:w-auto border-2 border-gray-500 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
              placeholder="e-mail"
              onFocus={() => setActive(true)}
              required
            />
          </label>
          <label
            htmlFor="privacy-checkbox"
            className={`flex justify-start items-center mt-2 md:mt-4 duration-500 transition-opacity ${
              active ? "" : "hidden md:opacity-0 md:block"
            }`}
          >
            <input
              type="checkbox"
              id="privacy-checkbox"
              className="text-gray-400 rounded mr-2 focus:ring-offset-0 focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
              required
            />
            <span className="text-gray-300 text-sm translate-y-px">
              {t("newsletter.accept")}{" "}
              <Link href="/privacy-policy">
                <a className="border-b text-white">
                  {t("newsletter.privacy-policy")}
                </a>
              </Link>
            </span>
          </label>
        </div>
        <button
          className={classNames(
            "hover:bg-white border-2 border-gray-500 mt-4 flex justify-center items-center rounded md:mt-0 md:ml-6 h-11 leading-none duration-500 transition-all min-w-[6rem] w-full md:w-auto",
            active ? "" : "hidden md:opacity-0 md:block",
            isValid ? "bg-white" : "bg-gray-500"
          )}
          disabled={!isValid}
          type="submit"
        >
          {t("newsletter.button")}
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
