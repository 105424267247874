import { Menu, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import Link from "next/link";
import { FC } from "react";

import { classNames } from "@utils/class-names";
import { setCookie } from "@utils/cookies";
import MontenegrinFlag from "@components/icons/montenegrin";
import EnglishFlag from "@components/icons/english";

const LanguageSwitch: FC = () => {
  const { pathname, query, ...router } = useRouter();

  const handleClick = (locale: string) => {
    setCookie("NEXT_LOCALE", locale, {});
  };

  const switchContent = (
    <div className="flex items-center justify-center">
      <div className="w-4">
        {router.locale === "en" ? <EnglishFlag /> : <MontenegrinFlag />}
      </div>
    </div>
  );

  return (
    <Menu as="div">
      {({ open }) => (
        <div className="relative w-10 h-10">
          <Menu.Button className="text-white cursor-pointer flex items-center justify-center w-full h-full">
            {switchContent}
          </Menu.Button>
          <Transition
            show={open}
            enter="transition duration-200 origin-top ease-out"
            enterFrom="scale-0"
            enterTo="scale-100"
            leave="transition duration-100 origin-top ease-in"
            leaveFrom="scale-100"
            leaveTo="scale-0"
          >
            <Menu.Items
              static
              className="absolute -translate-x-3/4 bg-black rounded flex flex-col justify-center items-center text-white w-28"
            >
              <Menu.Item disabled={router.locale === "en"}>
                {({ active, disabled }) => (
                  <div className="">
                    <Link href={{ pathname, query }} locale="en">
                      <a
                        onClick={() => handleClick("en")}
                        className={classNames(
                          "text-center block w-24 h-full my-2 py-2 transition-colors duration-100",
                          active ? "bg-gray-400" : "",
                          disabled ? "bg-gray-400 underline" : "cursor-pointer"
                        )}
                      >
                        English
                      </a>
                    </Link>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={router.locale === "me"}>
                {({ active, disabled }) => (
                  <div className="border-t border-white">
                    <Link href={{ pathname, query }} locale="me">
                      <a
                        onClick={() => handleClick("me")}
                        className={classNames(
                          "text-center block w-24 h-full my-2 py-2 transition-colors duration-100",
                          active ? "bg-gray-400" : "",
                          disabled ? "bg-gray-400 underline" : "cursor-pointer"
                        )}
                      >
                        Crnogorski
                      </a>
                    </Link>
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default LanguageSwitch;
