import { FC } from "react";
import XIcon from "@heroicons/react/solid/XIcon";
import Link from "next/link";
import { Product } from "@prisma/client";
import { useRouter } from "next/router";

import QuantityInput from "@components/products/quantity-input";
import { useCart } from "@context/cart";
import { ActionType } from "@interfaces/cart";
import { calculatePrice, formatPrice } from "@utils/price";
import { getMediaWithSize } from "@utils/get-media";

interface Props {
  product: Product;
  count: number;
}

const CartItem: FC<Props> = ({ product, count: quantity }) => {
  const { dispatch } = useCart();
  const { locale } = useRouter();

  return (
    <div className="text-black flex flex-row rounded bg-white p-4 border border-black">
      <Link
        href={{
          pathname: "/products/[slug]",
          query: { slug: product.slug },
        }}
      >
        <a className="w-2/12 rounded overflow-hidden block">
          <img
            src={getMediaWithSize(product.featuredPhoto, "sm")}
            alt={locale === "en" ? product.nameEn : product.name}
            className="w-full h-full object-cover hover:scale-110 transition-all duration-200"
          />
        </a>
      </Link>
      <div className="w-8/12 px-3 flex flex-col items-start">
        <div>
          <Link
            href={{
              pathname: "/products/[slug]",
              query: { slug: product.slug },
            }}
          >
            <a>
              <h3 className="font-bold text-md leading-4">
                {locale === "en" ? product.nameEn : product.name}
              </h3>
            </a>
          </Link>
          <span className="block text-sm font-light -mt-1 pt-2">
            {product.pantheonCode}
          </span>
        </div>
        <div className="scale-75 -translate-x-1/4 ml-3 translate-y-1">
          <QuantityInput
            {...{
              quantity,
              increase: () =>
                dispatch({ type: ActionType.INCREASE_COUNT, product }),
              decrease: () =>
                dispatch({ type: ActionType.DECREASE_COUNT, product }),
              set: (count) =>
                dispatch({ type: ActionType.SET_COUNT, product, count }),
            }}
          />
        </div>
      </div>
      <div className="w-2/12 flex flex-col justify-between items-end">
        <XIcon
          className="w-5 cursor-pointer hover:scale-125 transition-all duration-200"
          onClick={() =>
            dispatch({ type: ActionType.REMOVE_FROM_CART, product })
          }
        />

        <div className="relative font-bold">
          {product.discount !== 0 && (
            <div className="absolute -top-3 -right-1 text-red-600">
              {formatPrice(
                calculatePrice(product.price, product.discount),
                true
              )}
            </div>
          )}
          <div
            {...{
              className:
                product.discount !== 0
                  ? "line-through font-normal text-sm"
                  : undefined,
            }}
          >
            {formatPrice(product.price, true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
