type Cookies = {
  [cookieName: string]: string;
};

type CookieAttributes = Partial<{
  "max-age": number;
  expires: Date;
  domain: string;
  path: string;
  secure: boolean;
  samesite: "Strict" | "Lax" | "None";
  httpOnly: boolean;
}>;

export const getCookies = (): Cookies => {
  return document.cookie
    ? Object.fromEntries(
        document.cookie.split("; ").map((cookie) => cookie.split("="))
      )
    : {};
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  attributes: Omit<CookieAttributes, "httpOnly">
): void => {
  let cookie = `${cookieName}=${cookieValue}`;

  const { secure, ...attrs } = attributes;

  let attr: keyof typeof attrs;
  for (attr in attrs) {
    const value = attr === "expires" ? attrs[attr]?.toUTCString() : attrs[attr];
    if (value) {
      cookie += `;${attr}=${value}`;
    }
  }

  if (secure) {
    cookie += ";secure";
  }

  document.cookie = cookie;
};

export function createCookie(
  cookieName: string,
  cookieValue: string,
  attributes: CookieAttributes
): string {
  let cookie = `${cookieName}=${cookieValue}`;

  const { secure, httpOnly, ...attrs } = attributes;

  let attr: keyof typeof attrs;

  for (attr in attrs) {
    const value = attr === "expires" ? attrs[attr]?.toUTCString() : attrs[attr];
    if (value) {
      cookie += `;${attr}=${value}`;
    }
  }

  if (secure) {
    cookie += ";secure";
  }

  if (httpOnly) {
    cookie += ";httpOnly";
  }

  cookie += ";path=/";

  return cookie;
}
