import { Dispatch, FC, Fragment, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ShoppingBagIcon from "@heroicons/react/outline/ShoppingBagIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import CartItem from "@components/common/cart-item";
import { useCart } from "@context/cart";
import { getTotalItems } from "@lib/cart";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

const CartSlideOver: FC<Props> = ({ open, setOpen, className }) => {
  const { cart } = useCart();
  const cartItemCount = getTotalItems(cart);
  const { t } = useTranslation("common", { keyPrefix: "header" });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed inset-0 overflow-hidden ${
          className ? className : ""
        }`}
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-40" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-black shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex justify-center relative">
                      <Dialog.Title className="text-lg font-medium text-white flex">
                        <div>
                          {t("cart.label")} ({cartItemCount})
                        </div>
                        <ShoppingBagIcon className="w-6 ml-2" />
                      </Dialog.Title>
                      <div className="absolute right-0 h-7 flex items-center">
                        <button
                          className="bg-black rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">{t("cart.close")}</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 overflow-y-auto px-4 sm:px-6 space-y-2">
                    {cart.items.length ? (
                      cart.items.map((item) => (
                        <CartItem key={item.product.id} {...item} />
                      ))
                    ) : (
                      <div className="text-white h-full w-full flex justify-center items-center">
                        {t("cart.empty")}
                      </div>
                    )}
                  </div>
                  {cartItemCount > 0 && (
                    <div className="w-full flex justify-center items-center mt-4">
                      <Link href="/checkout">
                        <a className="h-10 border rounded border-gray-500 flex justify-center items-center px-4 text-xl bg-gray-500 hover:bg-white overflow-none transition duration-200">
                          <span className="transition duration-200">
                            {t("cart.checkout")}
                          </span>
                        </a>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CartSlideOver;
