import { FC, useEffect, useRef, useState } from "react";
import SearchIcon from "@heroicons/react/solid/SearchIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import { useRouter } from "next/router";
import { useDebounceCallback } from "@react-hook/debounce";
import { useTranslation } from "next-i18next";

import SearchPredictionCard from "./search-prediction-card";

import { getPredictions, Hit } from "@lib/algolia";
import { useClickOutside } from "@hooks/use-click-outside";

interface Props {
  small?: boolean;
  setSearchVisible?(searchVisible: boolean): void;
}

const Search: FC<Props> = ({ small, setSearchVisible }) => {
  const router = useRouter();
  const [search, setSearch] = useState(router.query["query"] ?? "");
  const [predictions, setPredictions] = useState<Hit[]>([]);
  const { t } = useTranslation("common", { keyPrefix: "header" });

  const [showPredictions, setShowPredictions] = useState(false);

  const getDebouncedPredictions = useDebounceCallback(async (query: string) => {
    const predictionResults = await getPredictions(query);
    setPredictions(predictionResults);
  }, 100);

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const escHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShowPredictions(false);
        inputRef.current?.blur();
      }
    };
    document.addEventListener("keydown", escHandler);

    return () => document.removeEventListener("keydown", escHandler);
  }, [ref]);

  useClickOutside(ref, () => setShowPredictions(false));

  const searchInput = (
    <>
      <label htmlFor="search" className="sr-only">
        {t("search.label")}
      </label>
      <div ref={ref} className="relative lg:w-[20vw] max-w-full">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id="search"
          ref={inputRef}
          name="search"
          className="block w-full pl-10 pr-3 py-2 border border-gray-400 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
          placeholder={t("search.placeholder")}
          type="text"
          autoComplete="off"
          value={search}
          onChange={async ({ target: { value } }) => {
            setSearch(value);
            getDebouncedPredictions(value);
          }}
          onFocus={() => !showPredictions && setShowPredictions(true)}
          onKeyDown={async ({ code }) => {
            if (code === "Enter" && search) {
              await router.push({
                pathname: "/search/[query]",
                query: { query: search },
              });
              router.reload();
            }
          }}
        />
        {showPredictions && (
          <div className="absolute left-0 w-screen max-w-xs lg:max-w-[30rem] rounded bg-[#f5f5f5] px-2 divide-y-2 divide-black border border-black shadow-xl">
            {predictions.length > 0 ? (
              predictions.map(({ _highlightResult, ...product }) => (
                <SearchPredictionCard
                  {...{
                    ...product,
                    name: _highlightResult?.name?.value ?? "",
                    description: _highlightResult?.description?.value ?? "",
                  }}
                  key={product.objectID}
                />
              ))
            ) : (
              <div className="min-h-[3rem] flex justify-center items-center">
                {t("search.no-results")}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {small ? (
        <div className="w-full flex items-center">
          <div className="lg:ml-4 w-full block pr-4">{searchInput}</div>
          <XIcon
            className="block h-6 w-6 text-white"
            aria-hidden="true"
            onClick={() => setSearchVisible && setSearchVisible(false)}
          />
        </div>
      ) : (
        <div className="xl:ml-4 xl:max-w-lg hidden xl:block">{searchInput}</div>
      )}
    </>
  );
};

export default Search;
