import { signOut, useSession } from "next-auth/client";
import { Dispatch, FC, Fragment, SetStateAction } from "react";
import Link from "next/link";
import { Dialog, Transition } from "@headlessui/react";
import UserCircleIcon from "@heroicons/react/outline/UserCircleIcon";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { useStaticData } from "@context/static";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMenuSlideover: FC<Props> = ({ open, setOpen }) => {
  const { categories } = useStaticData();
  const [session] = useSession();

  const { t } = useTranslation("common", { keyPrefix: "header" });
  const { locale } = useRouter();

  const logOut = () => {
    signOut({ redirect: false });
  };

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog
        static
        as="div"
        className="xl:hidden border-t fixed inset-0 overflow-hidden z-20"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black/40" />
          <div className="fixed inset-x-0 top-0">
            <Transition.Child
              as="div"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="bg-black pt-2">
                <div className="pt-16 pb-3 space-y-1">
                  {categories.map(({ slug, name, nameEn }) => (
                    <Link
                      key={slug}
                      href={{ pathname: "/categories/[slug]", query: { slug } }}
                    >
                      <a className="text-white block pl-3 pr-4 py-2 text-base font-medium">
                        {locale === "en" ? nameEn : name}
                      </a>
                    </Link>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-200">
                  {session && (
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <UserCircleIcon className="h-10 w-10 text-white" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-300">
                          {session.user.name}
                        </div>
                        <div className="text-sm font-medium text-gray-400">
                          {session.user.email}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="space-y-1 border-b border-gray-200 pb-3">
                    {session ? (
                      <>
                        <Link href="/user/profile">
                          <a className="block px-4 py-2 text-base font-medium text-white mt-3">
                            {t("header.profile")}
                          </a>
                        </Link>
                        <button
                          className="block px-4 py-2 text-base font-medium text-white"
                          onClick={() => logOut()}
                        >
                          {t("header.logout")}
                        </button>
                      </>
                    ) : (
                      <Link href="/auth/login">
                        <a className="block px-4 py-2 text-base font-medium text-white">
                          {t("header.login")}
                        </a>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenuSlideover;
