import { Dispatch } from "react";

export enum Sort {
  "RELEVANCY" = "RELEVANCY",
  "PRICE_LOW_HIGH" = "PRICE_LOW_HIGH",
  "PRICE_HIGH_LOW" = "PRICE_HIGH_LOW",
  "LATEST" = "LATEST",
  "MOST_SOLD" = "MOST_SOLD",
}

export type SortKey = keyof typeof Sort;

export const getSortKeys = (): SortKey[] => {
  const values = new Array<SortKey>(0);

  for (const key in Sort) {
    if (isNaN(+key)) {
      values.push(key as SortKey);
    }
  }

  return values;
};

export function getSortValues(): Sort[] {
  const values = new Array<Sort>(0);

  for (const key in Sort) {
    if (isNaN(+key)) {
      values.push(Sort[key as SortKey]);
    }
  }

  return values;
}

export type Range = [min: number, max: number];

export interface Measures {
  price: Range;
  height: Range;
  width: Range;
  length: Range;
}

export interface Filters {
  sortType: Sort;
  colorSet: Set<string>;
  materialSet: Set<string>;
  stylesSet: Set<string>;
  areasSet: Set<string>;
  suppliersSet: Set<string>;
  price: number[];
  lengthQuery: number[];
  heightQuery: number[];
  widthQuery: number[];
  priceIsSet?: boolean;
  lengthIsSet?: boolean;
  heightIsSet?: boolean;
  widthIsSet?: boolean;
}

export interface SerializableFilters {
  sortType: Sort;
  colorSet: Array<string>;
  materialSet: Array<string>;
  styleSet: Array<string>;
  areaSet: Array<string>;
  supplierSet: Array<string>;
  price: number[];
  lengthQuery: number[];
  heightQuery: number[];
  widthQuery: number[];
  priceIsSet?: boolean;
  lengthIsSet?: boolean;
  heightIsSet?: boolean;
  widthIsSet?: boolean;
}

export function deserializeFilters({
  areaSet,
  colorSet,
  materialSet,
  styleSet,
  ...filters
}: SerializableFilters): Filters {
  return {
    ...filters,
    areasSet: new Set(areaSet),
    colorSet: new Set(colorSet),
    materialSet: new Set(materialSet),
    stylesSet: new Set(styleSet),
    suppliersSet: new Set(filters.supplierSet),
  };
}

export function serializeFilters({
  areasSet,
  colorSet,
  materialSet,
  stylesSet,
  ...filters
}: Filters): SerializableFilters {
  return {
    ...filters,
    areaSet: Array.from(areasSet),
    colorSet: Array.from(colorSet),
    materialSet: Array.from(materialSet),
    styleSet: Array.from(stylesSet),
    supplierSet: Array.from(filters.suppliersSet),
  };
}

export interface FilterValue {
  name: string;
  value: string;
  number: number;
}

export enum ActionType {
  SET_SORT,
  CLEAR_SORT,

  SET_COLOR,
  REMOVE_COLOR,
  CLEAR_COLOR,

  SET_MATERIAL,
  REMOVE_MATERIAL,
  CLEAR_MATERIAL,

  SET_STYLE,
  REMOVE_STYLE,
  CLEAR_STYLE,

  SET_AREA,
  REMOVE_AREA,
  CLEAR_AREA,

  SET_SUPPLIER,
  REMOVE_SUPPLIER,
  CLEAR_SUPPLIER,

  SET_PRICE,
  CLEAR_PRICE,

  SET_LENGTH,
  CLEAR_LENGTH,

  SET_HEIGHT,
  CLEAR_HEIGHT,

  SET_WIDTH,
  CLEAR_WIDTH,

  INIT,
  RESET,
}

export interface State extends Filters {
  totalNumberOfProducts: number;
}

export type SetRemoveStringValueType =
  | ActionType.SET_COLOR
  | ActionType.REMOVE_COLOR
  | ActionType.SET_MATERIAL
  | ActionType.REMOVE_MATERIAL
  | ActionType.SET_STYLE
  | ActionType.REMOVE_STYLE
  | ActionType.SET_AREA
  | ActionType.REMOVE_AREA
  | ActionType.SET_SUPPLIER
  | ActionType.REMOVE_SUPPLIER;

export type SetNumberValueType =
  | ActionType.SET_PRICE
  | ActionType.SET_LENGTH
  | ActionType.SET_HEIGHT
  | ActionType.SET_WIDTH;

export type ClearType =
  | ActionType.CLEAR_AREA
  | ActionType.CLEAR_COLOR
  | ActionType.CLEAR_HEIGHT
  | ActionType.CLEAR_LENGTH
  | ActionType.CLEAR_MATERIAL
  | ActionType.CLEAR_PRICE
  | ActionType.CLEAR_SORT
  | ActionType.CLEAR_STYLE
  | ActionType.CLEAR_SUPPLIER
  | ActionType.CLEAR_WIDTH
  | ActionType.RESET;

export type Action =
  | { type: ActionType.SET_SORT; value: Sort }
  | { type: SetNumberValueType; value: number[] }
  | { type: SetRemoveStringValueType; value: string }
  | { type: ClearType }
  | { type: ActionType.INIT; value: Partial<Filters> };

export interface ContextType {
  filters: State;
  dispatch: Dispatch<Action>;
}
