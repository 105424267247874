import { Fragment, FC, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Menu, Transition } from "@headlessui/react";
import SearchIcon from "@heroicons/react/solid/SearchIcon";
import UserCircleIcon from "@heroicons/react/outline/UserCircleIcon";
import ShoppingCartIcon from "@heroicons/react/outline/ShoppingCartIcon";
import MenuIcon from "@heroicons/react/outline/MenuIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import { signOut, useSession } from "next-auth/client";
import { useTranslation } from "next-i18next";

import LanguageSwitch from "@components/common/language-switch";
import Search from "@components/common/search";
import CartSlideOver from "@components/common/cart-slide-over";
import MobileMenuSlideover from "@components/common/mobile-menu-slideover";
import { classNames } from "@utils/class-names";
import { useCart } from "@context/cart";
import { useStaticData } from "@context/static";
import { getTotalItems } from "@lib/cart";
import { getMediaWithSize } from "@utils/get-media";

const Nav: FC = () => {
  const router = useRouter();
  const { t } = useTranslation("common", { keyPrefix: "header" });

  const [searchVisible, setSearchVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);

  const [session] = useSession();

  const { cart } = useCart();
  const cartItemCount = getTotalItems(cart);

  const { categories } = useStaticData();

  const logOut = () => {
    signOut({ redirect: false });
  };

  return (
    <nav className="bg-black relative z-30">
      <div className="px-4">
        <div className="flex justify-between items-center h-16">
          {searchVisible ? (
            <Search {...{ small: true, setSearchVisible }} />
          ) : (
            <>
              <div className="flex items-center">
                <Link href="/">
                  <a className="flex-shrink-0 flex items-center">
                    <img
                      className="h-12 w-auto"
                      src={getMediaWithSize("/logo-white.png", "sm")}
                      alt="logo"
                    />
                  </a>
                </Link>
                <Search />
                <div className="hidden xl:ml-6 xl:flex xl:space-x-8">
                  {categories.map(({ slug, name, nameEn }) => (
                    <Link
                      key={slug}
                      href={{
                        pathname: "/categories/[slug]",
                        query: { slug },
                      }}
                    >
                      <a className="border-transparent text-gray-300 hover:border-white hover:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium text-center">
                        {router.locale === "en" ? nameEn : name}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex">
                <div className="xl:hidden">
                  <LanguageSwitch />
                </div>
                <div className="flex items-center xl:hidden">
                  <button
                    className="p-2 flex-shrink-0 focus:outline-none"
                    onClick={() => setSearchVisible(true)}
                  >
                    <span className="sr-only">{t("header.search")}</span>
                    <SearchIcon
                      className="h-6 w-6 text-gray-300 hover:text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="flex items-center xl:hidden">
                  {router.pathname !== "/checkout" && (
                    <button
                      className="p-2 flex-shrink-0 focus:outline-none relative"
                      onClick={() => setCartVisible(true)}
                    >
                      {cartItemCount > 0 && (
                        <span className="absolute top-0 -right-0 text-sm rounded-full z-10 leading-none h-5 w-5 font-bold text-white bg-red-500 flex items-center justify-center">
                          {cartItemCount}
                        </span>
                      )}

                      <span className="sr-only">{t("header.cart")}</span>
                      <ShoppingCartIcon
                        className="h-6 w-6 text-gray-300 hover:text-white"
                        aria-hidden="true"
                      />
                    </button>
                  )}
                </div>
                <div className="flex items-center xl:hidden">
                  {/* Mobile menu button */}
                  <button
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white focus:outline-none focus:text-white"
                    onClick={() => {
                      setMobileMenuVisible((old) => !old);
                    }}
                  >
                    <span className="sr-only">{t("header.menu")}</span>
                    {mobileMenuVisible ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="hidden xl:ml-4 xl:flex xl:items-center">
            <LanguageSwitch />

            {router.pathname !== "/checkout" && (
              <button
                className="flex-shrink-0 p-1 text-gray-400 focus:outline-none relative"
                onClick={() => setCartVisible(true)}
              >
                {cartItemCount > 0 && (
                  <span className="absolute -top-1 -right-0 rounded-full z-10 leading-none h-5 w-5 font-bold text-white bg-red-500 text-sm flex items-center justify-center">
                    {cartItemCount}
                  </span>
                )}
                <span className="sr-only">{t("header.cart")}</span>
                <ShoppingCartIcon
                  className="h-6 w-6 text-gray-300 hover:text-white"
                  aria-hidden="true"
                />
              </button>
            )}

            {/* Profile dropdown */}
            <Menu as="div" className="ml-4 relative flex-shrink-0">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="flex text-sm focus:outline-none">
                      <span className="sr-only">{t("header.user-menu")}</span>
                      <UserCircleIcon className="h-6 w-6 text-gray-300 hover:text-white" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      {session ? (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <Link href="/user/profile">
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {t("header.profile")}
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                )}
                                onClick={() => logOut()}
                              >
                                {t("header.logout")}
                              </div>
                            )}
                          </Menu.Item>
                        </>
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <Link href="/auth/login">
                              <a
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {t("header.login")}
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>

      <CartSlideOver
        open={cartVisible}
        setOpen={setCartVisible}
        className="z-50"
      />

      <MobileMenuSlideover
        open={mobileMenuVisible}
        setOpen={setMobileMenuVisible}
      />
    </nav>
  );
};

export default Nav;
