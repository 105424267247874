import { FC } from "react";

import { classNames } from "@utils/class-names";

interface Props {
  size: "small" | "large";
  dark?: boolean;
}

const images = [
  { alt: "visa", src: "/visa.webp" },
  { alt: "mastercard", src: "/mastercard.webp" },
  { alt: "maestro", src: "/maestro.webp" },
  { alt: "mastercard-id-check", src: "/idcheck.webp" },
  { alt: "visa-secure", src: "/visa-secure.webp" },
  { alt: "nlb", src: "/nlb.webp" },
];

const CardImages: FC<Props> = ({ dark, size }) => (
  <div
    className={classNames(
      "flex flex-wrap justify-center",
      size === "small" ? "space-x-4" : "space-x-2"
    )}
  >
    {images.map(({ alt, src }) => (
      <div
        key={alt}
        className={size === "small" ? "h-8 sm:h-10" : "h-10 sm:h-14"}
      >
        <img
          {...{ alt, src: `${dark ? "/dark" : "/light"}${src}` }}
          className="object-cover h-full"
        />
      </div>
    ))}
  </div>
);

export default CardImages;
