import { ImageLoaderProps } from "next/image";

import { environment, mediaRoot } from "@config/general";
import { environments } from "@constants/environments";

export const ImageSizes: Required<Sizes> = {
  xs: 200,
  sm: 400,
  md: 800,
  lg: 1024,
  xl: 1440,
  "2xl": 1920,
} as const;

export const Breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

type Breakpoint = keyof typeof Breakpoints;

interface Sizes {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  "2xl"?: number;
}

type ImageSize = keyof Sizes;

const allSizes = ["xs", "sm", "md", "lg", "xl", "2xl"] as ImageSize[];

export function getMedia(name: string): string {
  if (name.startsWith(mediaRoot)) {
    return name;
  } else {
    if (name.startsWith("http") || name.startsWith("//")) {
      return name;
    }
    if (mediaRoot.endsWith("/") !== name.startsWith("/")) {
      return mediaRoot + name;
    } else if (mediaRoot.startsWith("/")) {
      return mediaRoot.slice(0, -1) + name;
    }
    return `${mediaRoot}/${name}`;
  }
}

export function imageLoader({ src, width }: ImageLoaderProps): string {
  const size = (Object.entries(ImageSizes).find(
    ([, value]) => value >= width
  )?.[0] ?? "2xl") as ImageSize;

  const imgUrl = getMediaWithSize(src, size);

  if (environment === environments.DEVELOPMENT) {
    return `${imgUrl}?width=${width}`;
  }

  return imgUrl;
}

export function getMediaWithSize(name: string, size: ImageSize = "md"): string {
  const lastDot = name.lastIndexOf(".");
  const fileName = name.slice(0, lastDot);

  return getMedia(`${fileName}_${ImageSizes[size]}.webp`);
}

export function getMediaSrcSet(
  name: string,
  sizes: ImageSize[] = allSizes
): string {
  return sizes
    .map((size) => `${getMediaWithSize(name, size)} ${ImageSizes[size]}w`)
    .join(", ");
}

export function getMediaSizes(
  sizes: Array<{ breakpoint?: number | Breakpoint; size: string }>
): string {
  const queries = sizes
    .sort(({ breakpoint: a }, { breakpoint: b }) => {
      if (!a) {
        return 1;
      }

      if (!b) {
        return -1;
      }

      const aSize = typeof a === "number" ? a : Breakpoints[a];
      const bSize = typeof b === "number" ? b : Breakpoints[b];

      return aSize - bSize;
    })
    .map(({ breakpoint, size }, index) => {
      if (typeof breakpoint === "undefined") {
        return size;
      }

      const rightBound =
        typeof breakpoint === "number" ? breakpoint : Breakpoints[breakpoint];

      if (!index) {
        return `(max-width: ${rightBound}px) ${size}`;
      }

      if (index === sizes.length - 1) {
        return size;
      }

      const smallerBreakpoint = sizes[index - 1].breakpoint;

      if (typeof smallerBreakpoint === "undefined") {
        return `(max-width: ${rightBound}px) ${size}`;
      }

      const leftBound =
        typeof smallerBreakpoint === "number"
          ? smallerBreakpoint + 1
          : Breakpoints[smallerBreakpoint] + 1;

      return `((min-width: ${leftBound}px) and (max-width: ${rightBound}px)) ${size}`;
    });

  return queries.join(", ");
}
